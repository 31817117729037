<template>
	<svg
		version="1.1"
		id="Layer_1"
		xmlns="http://www.w3.org/2000/svg"
		xmlns:xlink="http://www.w3.org/1999/xlink"
		x="0px"
		y="0px"
		viewBox="0 0 334 54"
		style="enable-background: new 0 0 334 54"
		xml:space="preserve"
	>
		<g>
			<path
				fill="#1c2a2d"
				d="M4.2,45.2c-0.2-0.2-0.3-0.6,0-0.9l0,0l3.7-3.9c0.2-0.2,0.6-0.3,0.9,0l0,0c2.5,2.2,5.7,3.4,9,3.5
		c3.8,0,5.7-1.5,5.7-3.7c0-1.9-1.1-3-5.1-3.4L15,36.6c-6.3-0.6-9.5-3.9-9.5-9.1c0-6,4.3-9.8,12-9.8c4.2-0.1,8.3,1.2,11.6,3.8
		c0.3,0.2,0.3,0.6,0.1,0.9l0,0l-3.1,3.8c-0.2,0.3-0.6,0.3-0.9,0.1l0,0c-2.4-1.6-5.2-2.5-8.1-2.6c-3.1,0-4.7,1.3-4.7,3.2
		c0,1.9,1.1,2.9,5.1,3.3l3.3,0.3c6.5,0.6,9.6,4,9.6,9c0,6.2-4.5,10.5-13,10.5C11.1,50.1,6.7,47.6,4.2,45.2z"
			/>
			<path
				fill="#1c2a2d"
				d="M43.4,49.8c-6.2,0-8.6-3-8.6-9V24.4c0-0.2-0.1-0.4-0.3-0.4h-0.1h-2.1c-0.3,0-0.6-0.3-0.6-0.6v-4.3
		c0-0.3,0.3-0.6,0.6-0.6h2.1c0.2,0,0.4-0.1,0.4-0.3v-0.1V9.6c0-0.3,0.3-0.6,0.6-0.6h5.9c0.3,0,0.6,0.3,0.6,0.6v8.5
		c0,0.2,0.1,0.4,0.3,0.4h0.1h4.1c0.3,0,0.6,0.3,0.6,0.6v4.3c0,0.3-0.3,0.6-0.6,0.6h-4.1c-0.2,0-0.4,0.1-0.4,0.3v0.1v16
		c0,2.2,0.9,2.9,2.9,2.9h1.6c0.3,0,0.6,0.3,0.6,0.6V49c0,0.3-0.3,0.6-0.6,0.6L43.4,49.8L43.4,49.8z"
			/>
			<path
				fill="#1c2a2d"
				d="M49.4,41.6c-1.4-5-1.4-10.3,0-15.3c1.7-5.4,6.5-8.5,12.8-8.5s11,3.1,12.7,8.5c1.4,5,1.4,10.3,0,15.3
		c-1.7,5.4-6.5,8.5-12.7,8.5S51.1,47,49.4,41.6z M67.9,39.6c0.5-1.6,0.6-3.1,0.6-5.7s-0.1-4-0.6-5.7c-0.8-2.5-3.2-4.2-5.8-4
		c-2.6-0.1-5,1.5-5.8,4c-0.5,1.7-0.6,3.1-0.6,5.7s0.1,4.1,0.6,5.7c0.8,2.5,3.2,4.2,5.8,4C64.8,43.8,67.1,42.2,67.9,39.6L67.9,39.6z"
			/>
			<path
				fill="#1c2a2d"
				d="M78.4,41.6c-1.3-5-1.3-10.3,0-15.3c1.8-5.5,6.5-8.5,12.8-8.5c4.1-0.1,8.1,1.7,10.7,4.8c0.2,0.3,0.2,0.7,0,0.9
		l0,0l-4,3.5c-0.2,0.2-0.6,0.2-0.8,0L97,26.9c-1.5-1.7-3.1-2.7-5.7-2.7c-2.8,0-4.9,1.3-5.8,4c-0.5,1.9-0.7,3.8-0.6,5.7
		c-0.1,1.9,0.1,3.9,0.6,5.8c0.9,2.6,3,4,5.8,4c2.6,0,4.2-1,5.7-2.7c0.2-0.3,0.5-0.3,0.8-0.1l0.1,0.1l4,3.5c0.3,0.2,0.3,0.6,0.1,0.9
		l0,0c-2.7,3.1-6.6,4.9-10.7,4.7C84.9,50.1,80.2,47.1,78.4,41.6z"
			/>
			<path
				fill="#1c2a2d"
				d="M124.6,49.4c-0.4,0-0.8-0.2-1-0.6l-7.8-12.9l-4.2,4.8v8.2c0,0.3-0.3,0.6-0.6,0.6h-6c-0.3,0-0.6-0.3-0.6-0.6
		V6.4c0-0.3,0.3-0.6,0.6-0.6h5.9c0.3,0,0.6,0.3,0.6,0.6v25.2l10.6-12.5c0.3-0.4,0.7-0.6,1.2-0.6h6.5c0.2,0,0.4,0.1,0.4,0.3
		c0,0.1,0,0.2-0.1,0.3l-9.8,11.3l11.3,18.4c0.1,0.2,0.1,0.4-0.1,0.5c-0.1,0.1-0.2,0.1-0.3,0.1L124.6,49.4L124.6,49.4z"
			/>
			<path
				fill="#1c2a2d"
				d="M131,45.2c-0.2-0.2-0.3-0.6,0-0.9l0,0l3.7-3.9c0.2-0.2,0.6-0.3,0.9,0l0,0c2.5,2.2,5.7,3.4,9.1,3.5
		c3.8,0,5.7-1.5,5.7-3.7c0-1.9-1.1-3-5.1-3.4l-3.3-0.3c-6.3-0.6-9.5-3.9-9.5-9.1c0-6,4.3-9.8,12-9.8c4.2-0.1,8.3,1.2,11.6,3.8
		c0.3,0.2,0.3,0.6,0.1,0.9l0,0l-3.1,3.8c-0.2,0.3-0.6,0.3-0.9,0.1l0,0c-2.4-1.6-5.2-2.5-8.1-2.6c-3.1,0-4.7,1.3-4.7,3.2
		c0,1.9,1.1,2.9,5.1,3.3l3.3,0.3c6.5,0.6,9.6,4,9.6,9c0,6.2-4.5,10.5-13,10.5C137.9,50.1,133.6,47.6,131,45.2z"
			/>
			<path
				class="st1"
				fill="#3796f8"
				d="M169.5,49.8c-6.2,0-8.6-3-8.6-9V24.4c0-0.2-0.1-0.4-0.3-0.4h-0.1h-2.1c-0.3,0-0.6-0.3-0.6-0.6v-4.3
		c0-0.3,0.3-0.6,0.6-0.6h2.1c0.2,0,0.4-0.1,0.4-0.3v-0.1V9.6c0-0.3,0.3-0.6,0.6-0.6h5.9c0.3,0,0.6,0.3,0.6,0.6v8.5
		c0,0.2,0.1,0.4,0.3,0.4h0.1h4.1c0.3,0,0.6,0.3,0.6,0.6v4.3c0,0.3-0.3,0.6-0.6,0.6h-4.1c-0.2,0-0.4,0.1-0.4,0.3v0.1v16
		c0,2.2,0.9,2.9,2.9,2.9h1.6c0.3,0,0.6,0.3,0.6,0.6V49c0,0.3-0.3,0.6-0.6,0.6L169.5,49.8L169.5,49.8z"
			/>
			<path
				class="st1"
				fill="#3796f8"
				d="M175.5,41.6c-1.4-5-1.4-10.3,0-15.3c1.7-5.4,6.5-8.5,12.8-8.5s11,3.1,12.7,8.5c1.4,5,1.4,10.3,0,15.3
		c-1.7,5.4-6.5,8.5-12.7,8.5S177.2,47,175.5,41.6z M194,39.6c0.5-1.6,0.6-3.1,0.6-5.7s-0.1-4-0.6-5.7c-0.8-2.5-3.2-4.2-5.8-4
		c-2.6-0.1-5,1.5-5.8,4c-0.5,1.7-0.6,3.1-0.6,5.7s0.1,4.1,0.6,5.7c0.8,2.5,3.2,4.2,5.8,4C190.8,43.8,193.2,42.2,194,39.6L194,39.6z"
			/>
			<path
				fill="#1c2a2d"
				d="M214.3,49.8c-6.2,0-8.6-3-8.6-9V24.4c0-0.2-0.1-0.4-0.3-0.4h-0.1h-2.1c-0.3,0-0.6-0.3-0.6-0.6v-4.3
		c0-0.3,0.3-0.6,0.6-0.6h2.1c0.2,0,0.4-0.1,0.4-0.3v-0.1V9.6c0-0.3,0.3-0.6,0.6-0.6h5.9c0.3,0,0.6,0.3,0.6,0.6v8.5
		c0,0.2,0.1,0.4,0.3,0.4h0.1h4.1c0.3,0,0.6,0.3,0.6,0.6v4.3c0,0.3-0.3,0.6-0.6,0.6h-4.1c-0.2,0-0.4,0.1-0.4,0.3v0.1v16
		c0,2.2,0.9,2.9,2.9,2.9h1.6c0.3,0,0.6,0.3,0.6,0.6V49c0,0.3-0.3,0.6-0.6,0.6L214.3,49.8L214.3,49.8z"
			/>
			<path
				fill="#1c2a2d"
				d="M222.1,49.4c-0.3,0-0.6-0.3-0.6-0.6V19.1c0-0.3,0.3-0.6,0.6-0.6h5.9c0.3,0,0.6,0.3,0.6,0.6v2.6h0.1
		c1.5-2.4,4.1-4,8-4c2.4,0,4.8,0.9,6.5,2.6c0.3,0.2,0.4,0.5,0.2,0.8c0,0,0,0.1-0.1,0.1l-3.4,4.3c-0.2,0.3-0.5,0.4-0.8,0.2
		c0,0-0.1,0-0.1-0.1c-1.3-0.9-2.9-1.4-4.6-1.5c-4,0-5.8,2.9-5.8,7.8v16.8c0,0.3-0.3,0.6-0.6,0.6L222.1,49.4L222.1,49.4z"
			/>
			<path
				fill="#1c2a2d"
				d="M263.6,49.4c-0.3,0-0.6-0.3-0.6-0.6v-2.3h-0.1c-1.5,2.1-4.4,3.6-8.7,3.6c-5.6,0-10.4-3-10.4-9.4
		c0-6.7,4.7-9.8,12.4-9.8h6.3c0.2,0,0.4-0.1,0.4-0.3v-0.1V29c0-3.5-1.7-5.1-6.9-5.1c-3.5,0-5.6,1-7.1,2.1c-0.2,0.2-0.6,0.2-0.8,0
		l-0.1-0.1l-2.4-4.2c-0.2-0.3-0.1-0.6,0.1-0.8c2.5-1.8,5.9-3,11-3c9.4,0,12.9,3.2,12.9,10.8v20.2c0,0.3-0.3,0.6-0.6,0.6L263.6,49.4
		L263.6,49.4z M262.8,39v-2.4c0-0.2-0.1-0.4-0.3-0.4h-0.1h-5.1c-4.6,0-6.7,1.3-6.7,4.2c0,2.6,1.9,3.9,5.3,3.9
		C260.4,44.3,262.8,42.5,262.8,39L262.8,39z"
			/>
			<path
				fill="#1c2a2d"
				d="M292.3,49.4c-0.3,0-0.6-0.3-0.6-0.6v-2.3h-0.1c-1.5,2.1-4,3.7-8.2,3.7c-5.3,0-9.1-2.5-10.7-7.4
		c-0.8-2.4-1.1-4.6-1.1-8.7s0.3-6.4,1.1-8.7c1.6-4.9,5.5-7.4,10.7-7.4c4.2,0,6.7,1.5,8.2,3.7h0.1v-15c0-0.3,0.3-0.6,0.6-0.6h5.9
		c0.3,0,0.6,0.3,0.6,0.6v42.4c0,0.3-0.3,0.6-0.6,0.6L292.3,49.4L292.3,49.4z M291.1,39.7c0.5-1.4,0.6-3,0.6-5.8s-0.2-4.4-0.6-5.8
		c-0.8-2.6-2.8-4-5.8-4s-4.9,1.4-5.7,4c-0.5,1.4-0.6,3-0.6,5.8s0.2,4.4,0.6,5.8c0.8,2.6,2.8,4,5.7,4
		C288.2,43.7,290.2,42.3,291.1,39.7L291.1,39.7z"
			/>
			<path
				fill="#1c2a2d"
				d="M302.3,41.5c-0.8-2.4-1.1-5-1.1-7.6c-0.1-2.6,0.3-5.2,1-7.6c1.7-5.4,6.5-8.5,12.6-8.5c6.4,0,11,3.3,12.7,8.5
		c0.7,2.2,1,4.3,1,9.3c0,0.3-0.3,0.6-0.6,0.6h-0.1h-19.1c-0.2,0-0.4,0.1-0.4,0.3v0.1c0,0.8,0.2,1.6,0.5,2.4c1,3,3.5,4.7,6.9,4.7
		s5.7-1.3,7.3-3c0.2-0.3,0.6-0.4,0.9-0.2c0,0,0.1,0,0.1,0.1l3.8,3.3c0.3,0.2,0.3,0.5,0.1,0.8l-0.1,0.1c-2.6,3.1-7,5.3-12.7,5.3
		C308.7,50.1,304.1,46.9,302.3,41.5z M321,27.8c-0.8-2.5-3.1-4-6-4s-5.3,1.5-6.1,4c-0.3,0.8-0.4,1.7-0.4,2.6c0,0.2,0.1,0.4,0.3,0.4
		h0.1H321c0.2,0,0.4-0.1,0.4-0.3v-0.1C321.4,29.6,321.3,28.7,321,27.8z"
			/>
		</g>
	</svg>
</template>
