
import categorires_data from '../utils/data/categories-data.json';

export default defineNuxtPlugin(async () => {

    return {
        provide: {
            categories: categorires_data
        }
    }
});