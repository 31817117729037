<script setup>
const { navigation } = defineProps(["navigation"]);
const showSubmenu = ref(true);
const navbar = ref();

watch(navbar, (newNav) => {
	try {
		if (process.client && newNav) {
			const links = document.querySelectorAll(`.nav-submenu-link`);

			[...links].map((link) => {
				link.addEventListener("click", (e) => {
					const linkToBlur = document.activeElement;

					linkToBlur.parentElement.parentElement.parentElement.style.pointerEvents =
						"none";

					setTimeout(() => {
						linkToBlur.parentElement.parentElement.parentElement.style.pointerEvents =
							"auto";
					}, 50);

					e.stopPropagation();
				});

				return null;
			});
		}
	} catch (error) {
		if (env === "dev") {
			// eslint-disable-next-line no-console
			console.log("ERROR IN NAVIGATION", error);
		}
	}
});
</script>

<template>
	<nav
		class="nav-link-container hidden xl:flex items-center ml-auto"
		role="navigation"
		aria-label="Main"
		ref="navbar"
	>
		<div
			v-for="menu in navigation"
			:key="menu.ID"
			class="nav-link-block"
			tabindex="0"
			@mouseover="showSubmenu = true"
			@mouseleave="showSubmenu = false"
		>
			<NuxtLink
				:to="menu.url"
				class="nav-link"
			>
				{{ menu.title }}

				<svg
					v-if="menu.children"
					width="24"
					height="25"
					viewBox="0 0 24 25"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M7.42969 10L5.92969 11.5L12 17.5703L18.0703 11.5L16.5703 10L12 14.5703L7.42969 10Z"
						fill="white"
					/>
				</svg>
			</NuxtLink>

			<div
				v-if="menu.children && menu.children.length && showSubmenu"
				class="nav-submenu-container"
			>
				<div class="nav-container mx-auto grid grid-cols-4">
					<div
						v-for="(submenu, i) in menu.children"
						:key="i"
						class="nav-menu-block"
					>
						<Heading
							tag="h4"
							:text="submenu.title"
							class="nav-submenu-title"
						/>

						<NuxtLink
							v-for="item in submenu.children"
							:key="item.url"
							class="nav-submenu-link"
							:to="item.url"
							@click="showSubmenu = false"
						>
							{{ item.title }}
						</NuxtLink>
					</div>
				</div>
			</div>
		</div>
	</nav>
</template>

<style lang="scss" scoped>
#searchform input {
	background-color: $primary-50;
	padding: 8px 12px;
	border-top-left-radius: 8px;
	border-bottom-left-radius: 8px;
	border: 1px solid $primary-50;
	&:focus {
		outline-width: 0;
		outline-offset: 0;
		border: 1px solid $primary-700;
	}
}
#searchform button[type="submit"] {
	background-color: $primary-700;
	border: 1px solid $primary-700;

	display: flex;
	justify-content: center;
	align-items: center;
	gap: 8px;
	font-weight: 600;
	color: white;
	min-width: 120px;
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
	border-top-right-radius: 8px;
	border-bottom-right-radius: 8px;
}
.search-bar {
	background-color: white;
	padding: 32px 0;
	margin-left: -16px;
	margin-right: -16px;
	height: 0;
	transition: all 900ms ease-in-out;
	overflow: hidden;
	display: none;
}
.search-bar.active {
	height: 104px;
	display: block;
}

.search-bar .container.py-0 {
	padding-top: 0;
	padding-bottom: 0;
}
.mobile-menu-btn {
	background-color: white;
	border-radius: 8px;
	padding: 8px;

	&.close {
		background-color: transparent;
	}
}

.header.home.active {
	background-color: $primary-900;
}
.header {
	background-color: $primary-900;
	position: sticky;
	transition: all 100ms ease-in;
	z-index: 999;
	top: 0;

	&.home {
		background-color: $primary-900;
	}

	.container {
		padding: 10px 0;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	padding: 0 16px;

	&.has-progress-bar {
		margin-top: 0;
		top: 10px;
	}
}

.nav-link-block:focus .nav-link::before,
.nav-link-block:hover .nav-link::before {
	background-color: transparent;
	width: 100%;
	height: 32px;
	display: block;
	content: "";
	position: absolute;
	bottom: -32px;
}

.nav-link-block:focus .nav-link,
.nav-link-block:hover .nav-link {
	color: $yellow-500;
	path {
		fill: $yellow-500;
	}
}

.nav-link-block:focus .nav-submenu-container,
.nav-link-block:hover .nav-submenu-container {
	display: flex;
	justify-content: center;
}

.nav-link-block:focus {
	outline: 1px solid $primary-300;
}

.nav-submenu-container {
	display: none;

	.container {
		display: grid;
		grid-template-columns: repeat(4, 1fr);
		grid-auto-flow: dense;
	}
}

.nav-menu-block {
	display: flex;
	flex-direction: column;
	column-gap: 16px;
}

.nav-submenu-container {
	position: absolute;
	width: 100%;
	left: 0;
	top: 76px;
	z-index: 9999;
	right: 0;
	margin: auto;
	max-width: 1024px;
}

.nav-container {
	background-color: $white;
	border-radius: 16px;
	filter: drop-shadow(0px 2px 9px rgba(0, 0, 0, 0.12));
	padding: 32px;
	display: inline-flex;
	gap: 32px;
}

.nav-submenu-title {
	color: $slate-800;
	font-size: 20px;
	@include dmSans;
	margin-bottom: 12px;
	font-weight: 700;
	text-transform: capitalize;
}

.nav-submenu-link {
	font-size: 16px;
	font-weight: 400;
	padding: 8px;
	line-height: 1.25;
	color: $slate-800;
	display: inline-block;
	@include dmSans;

	&:hover {
		background-color: $yellow-100;
	}
}

.nav-link {
	color: #fff;
	@include dmSans;
	font-size: 16px;
	height: 40px;
	font-weight: 400;
	line-height: 40px;
	/* 36px */
	letter-spacing: 0.44px;
	position: relative;
	padding: 0 16px;
	visibility: visible;
	display: flex;
	align-items: center;
	gap: 4px;
}

span.caret {
	display: none;
	position: absolute;
	left: 0;
	right: 0;
	width: 64px;
	margin: auto;

	top: -16px;

	svg {
		width: 64px;
	}
}

span.caret {
	@include breakpoint(lg) {
		display: block;
	}
}
</style>
