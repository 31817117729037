<template>
	<button
		@click="onClick"
		:class="{ btn: true, 'btn-clear': type === 'clear' }"
	>
		<slot />
	</button>
</template>

<script setup>
const button = ref();

const { onClick, type } = defineProps(["onClick", "type"]);

onMounted(() => {});
</script>

<style lang="scss">
.btn {
	display: flex;
	align-items: center;
	gap: 4px;
	border-radius: 12px;
	text-align: center;
	font-size: 16px;
	font-style: normal;
	font-weight: 600;
	line-height: 25px;
	white-space: nowrap;

	background: $blue-300;
	color: white;
	&:hover {
		background: $blue-500;
	}

	&.btn-clear {
		background-color: transparent;
		border: 1px solid white;
	}
}
</style>
