<template>
	<Head>
		<Link
			rel="preconnect"
			href="https://fonts.googleapis.com/"
		/>
		<Link
			rel="preconnect"
			href="https://fonts.gstatic.com"
		/>
	</Head>

	<CookieConsent />
	<LazyBreakingNews v-if="showBreakingNews" />
	<stt-header class="bg-slate-500"></stt-header>

	<NuxtErrorBoundary>
		<!-- You use the default slot to render your content -->
		<template #error="{ error, clearError }">
			You can display the error locally here: {{ error }}
			<button @click="clearError">This will clear the error.</button>
		</template>
	</NuxtErrorBoundary>

	<slot />

	<div
		v-if="store.showSignUpModal"
		class="w-full max-w-[560px] shadow-lg bg-white z-[99] mx-auto p-[24px] left-0 bottom-[64px] right-0 rounded-[20px] fixed max-h-[360px] border-[3px] border-slate-100"
	>
		<button
			class="block ml-auto"
			@click="store.toggleSignUp()"
		>
			<svg
				xmlns="http://www.w3.org/2000/svg"
				height="24"
				viewBox="0 -960 960 960"
				width="24"
			>
				<path
					d="m256-200-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z"
				/>
			</svg>
		</button>
		<div
			class="text-[32px] lg:text-[40px] text-slate-900 text-center black mb-[32px] leading-tight"
		>
			Get Daily, FREE Trade Alerts From Our Algorithm
		</div>

		<LeadCaptureForm
			id="sttwl-mainsite-bottom"
			class="max-w-[792px]"
			alt="true"
			:altHanlder="() => store.toggleSignUp()"
		/>
	</div>
	<SttFooter />

	<AdButlerModal
		v-if="showAds && showAdButlerModal && show_adbutler"
		@close="hideModal"
		:ad-id="741013"
	/>
</template>

<script setup>
import "@stockstotrade_com/stt-ts-shared-lib/dist/index.css";
import {
	AdButlerModal,
	CookieConsent
} from "@stockstotrade_com/stt-ts-shared-lib";
import { store } from "~/src/utils/store";

const $route = useRoute();
const { env, current_hostname, show_adbutler } = useRuntimeConfig().public;

const showAdButlerModal = ref(false);
const showAds = ref(false);
const showBreakingNews = ref(false);

const loadAccessibe = () => {
	const s = document.createElement("script");
	const h = document.querySelector("head") || document.body;
	s.src = "https://acsbapp.com/apps/app/dist/js/app.js";
	s.async = true;
	s.onload = function () {
		acsbJS.init({
			statementLink: "",
			footerHtml: "",
			hideMobile: false,
			hideTrigger: false,
			disableBgProcess: false,
			language: "en",
			position: "right",
			leadColor: "#146FF8",
			triggerColor: "#146FF8",
			triggerRadius: "50%",
			triggerPositionX: "right",
			triggerPositionY: "bottom",
			triggerIcon: "people",
			triggerSize: "bottom",
			triggerOffsetX: 20,
			triggerOffsetY: 20,
			mobile: {
				triggerSize: "small",
				triggerPositionX: "right",
				triggerPositionY: "bottom",
				triggerOffsetX: 10,
				triggerOffsetY: 10,
				triggerRadius: "20"
			}
		});
	};
	h.appendChild(s);
};

useHead(() => {
	let prod_payload = [];
	let meta_payload = [];

	if (env !== "production") {
		meta_payload.push({
			name: "robots",
			content: "noindex, nofollow"
		});
	}

	return {
		link: [
			{ rel: "icon", type: "image/x-icon", href: "/favicon.ico" },
			{ rel: "manifest", href: "/site.webmanifest" },
			{
				rel: "icon",
				type: "image/png",
				sizes: "16x16",
				href: "/fav-16x16.png"
			},
			{
				rel: "icon",
				type: "image/png",
				sizes: "32x32",
				href: "/fav-32x32.png"
			},
			{ rel: "mask-icon", color: "#5BBAD5", href: "/safari-pinned-tab.svg" },
			{ rel: "icon", type: "apple-touch-icon", href: "/apple-touch-icon.png" }
		],
		script: [
			...prod_payload,
			{
				innerHTML: `
			window.weet_page_id = '${current_hostname.slice(0, -1)}${$route.path}';
			window.weet_page_type = 'optin';
			window.weet_page_rev = 'A';
			window.weet_funnel_id = 'stt_wl';
			`
			},
			{
				id: "mktlsid",
				type: "text/javascript",
				src: "https://hgevt001.stockstotrade.com/mkt-weet.js",
				"data-h": "tnd389f0za",
				"data-k":
					"azVFNGlxKXcxZEhuekdremIzZnpyUV5pQG9AeiFaaVk9by1ILkFUdjolSDpSSX1fLDhwM2o6IVBzPnF1dVNqVw==",
				"data-mak": "3W)UW4FU{sk2TX@D,r-3ss^+qeQ'xL]q",
				"data-mtld": "stockstotrade.com",
				"data-mhst": "mktlap",
				"data-phf": "1",
				"data-mgp": "1",
				"data-mofc": "optin_form",
				"data-mlo": "576293",
				body: true
			},
			{ src: "/js/prebid.js" }
		],
		meta: [...meta_payload]
	};
});

watch(
	() => $route.name,
	(newPage) => {
		if (newPage === "index" && isWithinTimeRanges())
			showBreakingNews.value = true;
		else if (newPage !== "index" && showBreakingNews.value)
			showBreakingNews.value = false;
	}
);

onMounted(async () => {
	if ($route.name === "index") {
		const isInTimeRange = isWithinTimeRanges();
		if (process.client && isInTimeRange) {
			await setTimeout(() => {
				showBreakingNews.value = true;
			}, 1000);
		}
	}

	if (process.client && window.adsAreWithUs) {
		showAds.value = true;

		/*	eslint-disable  prefer-const, no-undef */
		let timer;
		let timerCounter = 0;
		let scrollTimeout;

		function detectScrollStop(callback) {
			clearTimeout(scrollTimeout);
			scrollTimeout = setTimeout(callback, 3000); // 3000 milliseconds = 3 seconds
		}

		await setTimeout(() => {
			const adbutler_link = document.querySelectorAll(".watch-live-stt-header a");

			if (adbutler_link) {
				adbutler_link.forEach((item) => item.setAttribute("target", "_blank"));
			}
		}, 1000);

		timer = await setInterval(() => {
			const shouldShowAdButlerModal = localStorage.getItem("showAdButlerModal");

			function scrollHandler() {
				detectScrollStop(function () {
					const shouldShowAdButlerModal = localStorage.getItem("showAdButlerModal");

					const seconds_to_wait = env === "development" ? 3 : 6;

					if (
						timerCounter >= seconds_to_wait &&
						shouldShowAdButlerModal === "true"
					) {
						localStorage.setItem("showAdButlerModal", "true");
						showAdButlerModal.value = true;

						setTimeout(() => {
							hideModal();
							window.removeEventListener("scroll", scrollHandler);
						}, 25000);

						clearInterval(timer);
					}

					if (shouldShowAdButlerModal === "false" || !shouldShowAdButlerModal) {
						window.removeEventListener("scroll", scrollHandler);
						clearInterval(timer);
					}
				});
			}

			if (
				shouldShowAdButlerModal == null ||
				JSON.parse(shouldShowAdButlerModal) === true
			) {
				localStorage.setItem("showAdButlerModal", "true");
				window.addEventListener("scroll", scrollHandler);
			} else {
				localStorage.setItem("showAdButlerModal", "false");
				clearInterval(timer);
				return;
			}

			timerCounter += 1;
		}, 1000);
	}

	if (process.client) {
		loadAccessibe();
	}
});

const hideModal = () => {
	localStorage.setItem("showAdButlerModal", "false");
	showAdButlerModal.value = false;
};
</script>

<style lang="scss">
#header .plc750284 {
	@include max-breakpoint(lg) {
		display: none;
	}
}
.additional-info {
	display: flex;
	justify-content: space-between;
	align-items: center;
	a.post-tile:last-child {
		// display: none;
	}
	position: relative;
	p {
		position: absolute;
		right: 0;
		bottom: 0;
		top: 0;
		margin: auto;
		height: 16px;
	}
}

span.tim-bohen-thing {
	display: flex;
	gap: 8px;
}

.tim-bohen-pic {
	width: 34px;
	height: 34px;
	background-image: url("https://secure.gravatar.com/avatar/ffab25dfa1b032865bcfe0a47dde563a?s=48&d=mm&r=g");
	content: "";
	position: relative;
	display: block;
	background-size: cover;
	border-radius: 24px;
}
.modal-ad:before {
	display: none;
}
.adblutler-modal-close::after {
	position: absolute;
	z-index: 99;
	content: "";
	width: 20px;
	height: 20px;
	background-image: url("/images/close.svg");
	background-size: contain;
	top: 0;
	right: 0;
	bottom: 0;
	margin: auto;
}
.cookie-consent {
	background-color: rgb(5 41 143);
	border-bottom-color: $blue-300;
	z-index: 999;
}
.swiper-slide p {
	font-size: 15.2px;
}

.swiper-slide-button.prev {
	left: -36px;
	top: -52px;
}
.swiper-slide-button.next {
	right: -36px;
	top: -52px;
}

.testimonial-text .testimonial-author {
	font-size: 20px;
}
.testimonial-text h5 {
	font-size: 10.5px;
}
@media (min-width: 768px) {
	.swiper-slide p {
		font-size: 19px;
	}

	.swiper-slide-button.prev {
		left: 0;
		top: -32px;
	}
	.swiper-slide-button.next {
		right: 0;
		top: -32px;
	}
}

.saswp-rvw-str {
	display: inline-flex;
	width: 100%;
}

.str-ic {
	font-size: 18px;
	line-height: 1.2;
}

.saswp-rvw-str .str-ic {
	background-image: url(data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTkuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeD0iMHB4IiB5PSIwcHgiIHZpZXdCb3g9IjAgMCA0ODIuMjA3IDQ4Mi4yMDciIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDQ4Mi4yMDcgNDgyLjIwNzsiIHhtbDpzcGFjZT0icHJlc2VydmUiIHdpZHRoPSIxNnB4IiBoZWlnaHQ9IjE2cHgiPgo8cG9seWdvbiBwb2ludHM9IjQ4Mi4yMDcsMTg2Ljk3MyAzMjIuNTA4LDE1My4yNjkgMjQxLjEwNCwxMS44MDMgMTU5LjY5OSwxNTMuMjY5IDAsMTg2Ljk3MyAxMDkuMzg4LDMwOC4xMDggOTIuMDk0LDQ3MC40MDQgICAyNDEuMTA0LDQwMy44MDMgMzkwLjExMyw0NzAuNDA0IDM3Mi44MTgsMzA4LjEwOCAiIGZpbGw9IiNmZmQ3MDAiLz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPC9zdmc+Cg==);
}

.saswp-rvw-str .df-clr,
.saswp-rvw-str .half-str,
.saswp-rvw-str .str-ic {
	display: inline-block;
	width: 20px;
	height: 16px;
	background-repeat: no-repeat;
}

.single-post #commentform {
	padding-top: 2rem;
}

.saswp-rating-container {
	position: relative;
}

.jq-ry-container {
	position: relative;
	padding: 0 5px;
	line-height: 0;
	display: block;
	cursor: pointer;
	-webkit-box-sizing: content-box;
	-moz-box-sizing: content-box;
	box-sizing: content-box;
	direction: ltr;
}

.jq-ry-container > .jq-ry-group-wrapper {
	position: relative;
	width: 100%;
}
.jq-ry-container > .jq-ry-group-wrapper > .jq-ry-group.jq-ry-normal-group {
	width: 100%;
}
.jq-ry-container > .jq-ry-group-wrapper > .jq-ry-group {
	position: relative;
	line-height: 0;
	z-index: 10;
	white-space: nowrap !important;
}

.jq-ry-container > .jq-ry-group-wrapper > .jq-ry-group > svg {
	display: inline-block;
}

#toc_container li,
#toc_container ul {
	margin: 0;
	padding: 0;
}
#toc_container.no_bullets li,
#toc_container.no_bullets ul,
#toc_container.no_bullets ul li,
.toc_widget_list.no_bullets,
.toc_widget_list.no_bullets li {
	background: 0 0;
	list-style-type: none;
	list-style: none;
}
#toc_container.have_bullets li {
	padding-left: 12px;
}
#toc_container ul ul {
	margin-left: 1.5em;
}
#toc_container {
	padding: 10px;
	margin-bottom: 1em;
	width: auto;
	display: table;
	font-size: 95%;

	background: linear-gradient(110.32deg, #091d56 8.05%, #331290 77.58%);
	color: white;
	padding: 20px;
	border-radius: 12px;
}
#toc_container.toc_light_blue {
	background: #edf6ff;
}
#toc_container.toc_white {
	background: #fff;
}
#toc_container.toc_black {
	background: #000;
}
#toc_container.toc_transparent {
	background: none transparent;
}
#toc_container p.toc_title {
	text-align: center;
	font-weight: 700;
	margin: 0;
	padding: 0;
	color: white;
	padding: 0 8px;
}
#toc_container.toc_black p.toc_title {
	color: #aaa;
}
#toc_container.contracted .toc_list {
	display: none;
	transition: all 250ms ease-in-out;
}

.toc_list li a:not(.toc_list li ul li a) {
	font-weight: bold;
}
.toc_fake_link {
	color: #3295fb;
}

#toc_container span.toc_toggle {
	font-weight: 400;
	font-size: 90%;
	margin-left: 8px;
	cursor: pointer;
}
#toc_container:not(.contracted) p.toc_title + ul.toc_list {
	margin-top: 20px;
	display: flex;
	flex-direction: column;
	gap: 12px;

	ul {
		margin-top: 16px;
		display: flex;
		flex-direction: column;
		gap: 12px;
	}
}
#toc_container .toc_number {
	color: $blue-300;
}

#toc_container:not(.contracted) {
	max-width: 520px;
}
.toc_wrap_left {
	float: left;
	margin-right: 10px;
}
.toc_wrap_right {
	float: right;
	margin-left: 10px;
}
#toc_container a {
	text-decoration: none;
	text-shadow: none;
	color: $white;
}
#toc_container a:hover {
	text-decoration: underline;
}
.toc_sitemap_posts_letter {
	font-size: 1.5em;
	font-style: italic;
}

.end-of-article .related-post-info .read-more-link {
	font-size: 18px;
	margin-top: 20px;
	max-width: fit-content;
	align-items: center;
	position: relative;
	color: $blue-300;
	line-height: normal;
	&::after {
		content: "";
		background-image: url("/images/icons/external-link.svg");
		width: 14px;
		height: 14px;
		display: inline-block;
		background-size: contain;
		position: relative;
		left: -4px;
	}

	&:hover {
		// background-color: #eab308;
		// color: #1e3a8a;
		text-decoration: underline;
		&::after {
			background-image: url("/images/icons/external-link-hover.svg");
		}
	}
}

.end-of-article .related-post-info p {
	@include dmSans;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 28px;
	color: $slate-900;
	margin-bottom: 24px;
	a {
		color: $blue-300;
		&:hover {
			text-decoration: underline;
		}
	}

	@include breakpoint(lg) {
		font-size: 18px;
		margin-bottom: 28px;
	}

	img.aligncenter {
		margin: auto;
	}
}

.end-of-article .related-post-info h6 {
	color: $slate-800;

	/* Heading/H2 */
	font-size: 24px;
	font-style: normal;
	font-weight: 700;
	line-height: 120%;
	margin-bottom: 32px;

	/* 45.6px */
	letter-spacing: -0.76px;

	@include breakpoint(lg) {
		font-size: 32px;
	}
}
.end-of-article .related-post-info p a {
	font-size: 18px;
	display: revert-layer;
	align-items: center;
	position: relative;
	color: $blue-300;
	line-height: normal;
	&::after {
		content: "";
		background-image: url("/images/icons/external-link.svg");
		width: 14px;
		height: 14px;
		display: inline-block;
		background-size: contain;
		margin-left: 4px;
	}

	&:hover {
		text-decoration: underline;
	}
}

.watch-live-stt-header a:not(.blob) {
	@include max-breakpoint(lg) {
		display: none;
	}
}
.watch-live-stt-header .blob {
	@include max-breakpoint(lg) {
		margin-right: 32px !important;
	}
	@include breakpoint(lg) {
		margin-left: 20px !important;
	}
}

#author-page .row {
	@include max-breakpoint(lg) {
		padding-left: 20px;
		padding-right: 20px;
	}
}

#author-page li {
	font-size: 1.1rem;
	margin-bottom: 1.15rem;
	padding-left: 16px;
	position: relative;
}
#author-page li:before {
	background-color: #3094fb;
	content: "";
	height: 8px;
	left: 0;
	position: absolute;
	top: 0.5rem;
	width: 8px;
}
#author-page .author-highlights a:after {
	background-image: url(../images/icons/external-link.svg);
	background-size: contain;
	content: "";
	display: inline-block;
	height: 14px;
	margin-left: 2px;
	width: 14px;
}
#author-page .author-highlights a:hover {
	background-color: #eab308;
	color: #1e3a8a;
	text-decoration: underline;
}
#author-page .author-highlights a:hover:after {
	background-image: url(../images/icons/external-link-hover.svg);
}
#author-page .author-socialmedia {
	display: flex;
	gap: 12px;
	margin-top: 20px;
}

.infusion-form {
	display: flex;
	flex-direction: column;
	row-gap: 24px;
}
.infusion-field {
	display: flex;
	flex-direction: column;

	label {
		color: $slate-900;
		font-weight: 600;
		margin-bottom: 12px;
	}

	input {
		border-radius: 8px;
		border: 1px solid $slate-100;
		height: 40px;
		background-color: #f6f8fa;
	}
	textarea {
		border-radius: 8px;
		border: 1px solid $slate-100;
		background-color: #f6f8fa;
	}
}

.infusion-submit input[type="submit"] {
	cursor: pointer;

	background: linear-gradient(110.32deg, #091d56 8.05%, #331290 77.58%);
	color: white;
	width: 100%;
	border-radius: 12px;
	transition: all 250ms ease-in-out;
	display: flex;
	align-items: center;
	justify-content: center;
	min-height: 46px;
}
</style>
