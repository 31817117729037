<template>
	<svg
		viewBox="0 0 921 219"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M1 218.999L183.588 174.28L321.543 101.612H487.901L560.936 62.4826H796.271L840.904 45.713L880.352 30.3414L897.709 23.3535"
			stroke="url(#paint0_linear_2270_2364)"
			stroke-width="4.65823"
		/>
		<path
			d="M183.588 174.28L1 218.999H897.709V23.3535L840.904 45.713L796.271 62.4826H560.936L524.418 82.0472L487.901 101.612H321.543L183.588 174.28Z"
			fill="url(#paint1_linear_2270_2364)"
		/>
		<rect
			x="874.418"
			y="0.0634766"
			width="46.5823"
			height="46.5823"
			rx="23.2911"
			fill="#7F7CF8"
		/>
		<circle
			cx="897.709"
			cy="23.3546"
			r="15.1392"
			fill="#7F7CF8"
			stroke="white"
			stroke-width="6.98734"
		/>
		<defs>
			<linearGradient
				id="paint0_linear_2270_2364"
				x1="-45.6582"
				y1="13.154"
				x2="319.822"
				y2="761.419"
				gradientUnits="userSpaceOnUse"
			>
				<stop stop-color="#6562FB" />
				<stop
					offset="1"
					stop-color="#9795F4"
				/>
			</linearGradient>
			<linearGradient
				id="paint1_linear_2270_2364"
				x1="449.354"
				y1="23.3535"
				x2="449.354"
				y2="213.409"
				gradientUnits="userSpaceOnUse"
			>
				<stop
					stop-color="#8079FF"
					stop-opacity="0.16"
				/>
				<stop
					offset="1"
					stop-color="#8079FF"
					stop-opacity="0"
				/>
			</linearGradient>
		</defs>
	</svg>
</template>
