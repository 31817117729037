import { default as _91_46_46_46slug_93EhWyQvnGPmMeta } from "/opt/build/repo/pages/[...slug].vue?macro=true";
import { default as aboutlv6i1FL0CfMeta } from "/opt/build/repo/pages/about.vue?macro=true";
import { default as affiliate_45disclosurejQpPAyHWoGMeta } from "/opt/build/repo/pages/affiliate-disclosure.vue?macro=true";
import { default as ai_45disclosureMNeGNEPwRcMeta } from "/opt/build/repo/pages/ai-disclosure.vue?macro=true";
import { default as _91_46_46_46author_45name_93pJjHr4FXOXMeta } from "/opt/build/repo/pages/authors/[...author-name].vue?macro=true";
import { default as blogajPFud6by9Meta } from "/opt/build/repo/pages/blog.vue?macro=true";
import { default as broker_45integrationhAwNz13slsMeta } from "/opt/build/repo/pages/broker-integration.vue?macro=true";
import { default as careerswHPeqhkiyxMeta } from "/opt/build/repo/pages/careers.vue?macro=true";
import { default as _91_46_46_46id_93VDc7JRCu8XMeta } from "/opt/build/repo/pages/category/[...id].vue?macro=true";
import { default as ccpa_45policyYpJAvphv6hMeta } from "/opt/build/repo/pages/ccpa-policy.vue?macro=true";
import { default as contactNfojXvLlAkMeta } from "/opt/build/repo/pages/contact.vue?macro=true";
import { default as cookie_45policyZcRIsfqfIvMeta } from "/opt/build/repo/pages/cookie-policy.vue?macro=true";
import { default as disclaimercbAuCFHnKSMeta } from "/opt/build/repo/pages/disclaimer.vue?macro=true";
import { default as dmca_45takedown_45policy27iFkDoOzcMeta } from "/opt/build/repo/pages/dmca-takedown-policy.vue?macro=true";
import { default as editorial_45guidelines0SNO1CxoBJMeta } from "/opt/build/repo/pages/editorial-guidelines.vue?macro=true";
import { default as indexlaFeZuYZkUMeta } from "/opt/build/repo/pages/index.vue?macro=true";
import { default as plansPed13sMCSsMeta } from "/opt/build/repo/pages/plans.vue?macro=true";
import { default as platformxg2siY4KqnMeta } from "/opt/build/repo/pages/platform.vue?macro=true";
import { default as privacy_45policyQXTlhFuZ55Meta } from "/opt/build/repo/pages/privacy-policy.vue?macro=true";
import { default as highest_45volume_45stockst92TyFAT1cMeta } from "/opt/build/repo/pages/scans/highest-volume-stocks.vue?macro=true";
import { default as indexVKYgSWQw3MMeta } from "/opt/build/repo/pages/scans/index.vue?macro=true";
import { default as stock_45gainersCp6dRkbgqKMeta } from "/opt/build/repo/pages/scans/stock-gainers.vue?macro=true";
import { default as stock_45losers7b77ckoi3bMeta } from "/opt/build/repo/pages/scans/stock-losers.vue?macro=true";
import { default as unusual_45volume_45stocksf612VZPTysMeta } from "/opt/build/repo/pages/scans/unusual-volume-stocks.vue?macro=true";
import { default as system_45requirementsjk8TcwXSaWMeta } from "/opt/build/repo/pages/system-requirements.vue?macro=true";
import { default as _91_46_46_46slug_93a8IqAOqDnhMeta } from "/opt/build/repo/pages/tag/[...slug].vue?macro=true";
import { default as terms_45service_45use_45agreementM2fqjXwr4PMeta } from "/opt/build/repo/pages/terms-service-use-agreement.vue?macro=true";
import { default as top_45featuresr3h4KIgH78Meta } from "/opt/build/repo/pages/top-features.vue?macro=true";
export default [
  {
    name: _91_46_46_46slug_93EhWyQvnGPmMeta?.name ?? "slug",
    path: _91_46_46_46slug_93EhWyQvnGPmMeta?.path ?? "/:slug(.*)*",
    meta: _91_46_46_46slug_93EhWyQvnGPmMeta || {},
    alias: _91_46_46_46slug_93EhWyQvnGPmMeta?.alias || [],
    redirect: _91_46_46_46slug_93EhWyQvnGPmMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: aboutlv6i1FL0CfMeta?.name ?? "about",
    path: aboutlv6i1FL0CfMeta?.path ?? "/about",
    meta: aboutlv6i1FL0CfMeta || {},
    alias: aboutlv6i1FL0CfMeta?.alias || [],
    redirect: aboutlv6i1FL0CfMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/about.vue").then(m => m.default || m)
  },
  {
    name: affiliate_45disclosurejQpPAyHWoGMeta?.name ?? "affiliate-disclosure",
    path: affiliate_45disclosurejQpPAyHWoGMeta?.path ?? "/affiliate-disclosure",
    meta: affiliate_45disclosurejQpPAyHWoGMeta || {},
    alias: affiliate_45disclosurejQpPAyHWoGMeta?.alias || [],
    redirect: affiliate_45disclosurejQpPAyHWoGMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/affiliate-disclosure.vue").then(m => m.default || m)
  },
  {
    name: ai_45disclosureMNeGNEPwRcMeta?.name ?? "ai-disclosure",
    path: ai_45disclosureMNeGNEPwRcMeta?.path ?? "/ai-disclosure",
    meta: ai_45disclosureMNeGNEPwRcMeta || {},
    alias: ai_45disclosureMNeGNEPwRcMeta?.alias || [],
    redirect: ai_45disclosureMNeGNEPwRcMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/ai-disclosure.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46author_45name_93pJjHr4FXOXMeta?.name ?? "authors-authorname",
    path: _91_46_46_46author_45name_93pJjHr4FXOXMeta?.path ?? "/authors/:authorname(.*)*",
    meta: _91_46_46_46author_45name_93pJjHr4FXOXMeta || {},
    alias: _91_46_46_46author_45name_93pJjHr4FXOXMeta?.alias || [],
    redirect: _91_46_46_46author_45name_93pJjHr4FXOXMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/authors/[...author-name].vue").then(m => m.default || m)
  },
  {
    name: blogajPFud6by9Meta?.name ?? "blog",
    path: blogajPFud6by9Meta?.path ?? "/blog",
    meta: blogajPFud6by9Meta || {},
    alias: blogajPFud6by9Meta?.alias || [],
    redirect: blogajPFud6by9Meta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/blog.vue").then(m => m.default || m)
  },
  {
    name: broker_45integrationhAwNz13slsMeta?.name ?? "broker-integration",
    path: broker_45integrationhAwNz13slsMeta?.path ?? "/broker-integration",
    meta: broker_45integrationhAwNz13slsMeta || {},
    alias: broker_45integrationhAwNz13slsMeta?.alias || [],
    redirect: broker_45integrationhAwNz13slsMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/broker-integration.vue").then(m => m.default || m)
  },
  {
    name: careerswHPeqhkiyxMeta?.name ?? "careers",
    path: careerswHPeqhkiyxMeta?.path ?? "/careers",
    meta: careerswHPeqhkiyxMeta || {},
    alias: careerswHPeqhkiyxMeta?.alias || [],
    redirect: careerswHPeqhkiyxMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/careers.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46id_93VDc7JRCu8XMeta?.name ?? "category-id",
    path: _91_46_46_46id_93VDc7JRCu8XMeta?.path ?? "/category/:id(.*)*",
    meta: _91_46_46_46id_93VDc7JRCu8XMeta || {},
    alias: _91_46_46_46id_93VDc7JRCu8XMeta?.alias || [],
    redirect: _91_46_46_46id_93VDc7JRCu8XMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/category/[...id].vue").then(m => m.default || m)
  },
  {
    name: ccpa_45policyYpJAvphv6hMeta?.name ?? "ccpa-policy",
    path: ccpa_45policyYpJAvphv6hMeta?.path ?? "/ccpa-policy",
    meta: ccpa_45policyYpJAvphv6hMeta || {},
    alias: ccpa_45policyYpJAvphv6hMeta?.alias || [],
    redirect: ccpa_45policyYpJAvphv6hMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/ccpa-policy.vue").then(m => m.default || m)
  },
  {
    name: contactNfojXvLlAkMeta?.name ?? "contact",
    path: contactNfojXvLlAkMeta?.path ?? "/contact",
    meta: contactNfojXvLlAkMeta || {},
    alias: contactNfojXvLlAkMeta?.alias || [],
    redirect: contactNfojXvLlAkMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: cookie_45policyZcRIsfqfIvMeta?.name ?? "cookie-policy",
    path: cookie_45policyZcRIsfqfIvMeta?.path ?? "/cookie-policy",
    meta: cookie_45policyZcRIsfqfIvMeta || {},
    alias: cookie_45policyZcRIsfqfIvMeta?.alias || [],
    redirect: cookie_45policyZcRIsfqfIvMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/cookie-policy.vue").then(m => m.default || m)
  },
  {
    name: disclaimercbAuCFHnKSMeta?.name ?? "disclaimer",
    path: disclaimercbAuCFHnKSMeta?.path ?? "/disclaimer",
    meta: disclaimercbAuCFHnKSMeta || {},
    alias: disclaimercbAuCFHnKSMeta?.alias || [],
    redirect: disclaimercbAuCFHnKSMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/disclaimer.vue").then(m => m.default || m)
  },
  {
    name: dmca_45takedown_45policy27iFkDoOzcMeta?.name ?? "dmca-takedown-policy",
    path: dmca_45takedown_45policy27iFkDoOzcMeta?.path ?? "/dmca-takedown-policy",
    meta: dmca_45takedown_45policy27iFkDoOzcMeta || {},
    alias: dmca_45takedown_45policy27iFkDoOzcMeta?.alias || [],
    redirect: dmca_45takedown_45policy27iFkDoOzcMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/dmca-takedown-policy.vue").then(m => m.default || m)
  },
  {
    name: editorial_45guidelines0SNO1CxoBJMeta?.name ?? "editorial-guidelines",
    path: editorial_45guidelines0SNO1CxoBJMeta?.path ?? "/editorial-guidelines",
    meta: editorial_45guidelines0SNO1CxoBJMeta || {},
    alias: editorial_45guidelines0SNO1CxoBJMeta?.alias || [],
    redirect: editorial_45guidelines0SNO1CxoBJMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/editorial-guidelines.vue").then(m => m.default || m)
  },
  {
    name: indexlaFeZuYZkUMeta?.name ?? "index",
    path: indexlaFeZuYZkUMeta?.path ?? "/",
    meta: indexlaFeZuYZkUMeta || {},
    alias: indexlaFeZuYZkUMeta?.alias || [],
    redirect: indexlaFeZuYZkUMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/index.vue").then(m => m.default || m)
  },
  {
    name: plansPed13sMCSsMeta?.name ?? "plans",
    path: plansPed13sMCSsMeta?.path ?? "/plans",
    meta: plansPed13sMCSsMeta || {},
    alias: plansPed13sMCSsMeta?.alias || [],
    redirect: plansPed13sMCSsMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/plans.vue").then(m => m.default || m)
  },
  {
    name: platformxg2siY4KqnMeta?.name ?? "platform",
    path: platformxg2siY4KqnMeta?.path ?? "/platform",
    meta: platformxg2siY4KqnMeta || {},
    alias: platformxg2siY4KqnMeta?.alias || [],
    redirect: platformxg2siY4KqnMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/platform.vue").then(m => m.default || m)
  },
  {
    name: privacy_45policyQXTlhFuZ55Meta?.name ?? "privacy-policy",
    path: privacy_45policyQXTlhFuZ55Meta?.path ?? "/privacy-policy",
    meta: privacy_45policyQXTlhFuZ55Meta || {},
    alias: privacy_45policyQXTlhFuZ55Meta?.alias || [],
    redirect: privacy_45policyQXTlhFuZ55Meta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: highest_45volume_45stockst92TyFAT1cMeta?.name ?? "scans-highest-volume-stocks",
    path: highest_45volume_45stockst92TyFAT1cMeta?.path ?? "/scans/highest-volume-stocks",
    meta: highest_45volume_45stockst92TyFAT1cMeta || {},
    alias: highest_45volume_45stockst92TyFAT1cMeta?.alias || [],
    redirect: highest_45volume_45stockst92TyFAT1cMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/scans/highest-volume-stocks.vue").then(m => m.default || m)
  },
  {
    name: indexVKYgSWQw3MMeta?.name ?? "scans",
    path: indexVKYgSWQw3MMeta?.path ?? "/scans",
    meta: indexVKYgSWQw3MMeta || {},
    alias: indexVKYgSWQw3MMeta?.alias || [],
    redirect: indexVKYgSWQw3MMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/scans/index.vue").then(m => m.default || m)
  },
  {
    name: stock_45gainersCp6dRkbgqKMeta?.name ?? "scans-stock-gainers",
    path: stock_45gainersCp6dRkbgqKMeta?.path ?? "/scans/stock-gainers",
    meta: stock_45gainersCp6dRkbgqKMeta || {},
    alias: stock_45gainersCp6dRkbgqKMeta?.alias || [],
    redirect: stock_45gainersCp6dRkbgqKMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/scans/stock-gainers.vue").then(m => m.default || m)
  },
  {
    name: stock_45losers7b77ckoi3bMeta?.name ?? "scans-stock-losers",
    path: stock_45losers7b77ckoi3bMeta?.path ?? "/scans/stock-losers",
    meta: stock_45losers7b77ckoi3bMeta || {},
    alias: stock_45losers7b77ckoi3bMeta?.alias || [],
    redirect: stock_45losers7b77ckoi3bMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/scans/stock-losers.vue").then(m => m.default || m)
  },
  {
    name: unusual_45volume_45stocksf612VZPTysMeta?.name ?? "scans-unusual-volume-stocks",
    path: unusual_45volume_45stocksf612VZPTysMeta?.path ?? "/scans/unusual-volume-stocks",
    meta: unusual_45volume_45stocksf612VZPTysMeta || {},
    alias: unusual_45volume_45stocksf612VZPTysMeta?.alias || [],
    redirect: unusual_45volume_45stocksf612VZPTysMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/scans/unusual-volume-stocks.vue").then(m => m.default || m)
  },
  {
    name: system_45requirementsjk8TcwXSaWMeta?.name ?? "system-requirements",
    path: system_45requirementsjk8TcwXSaWMeta?.path ?? "/system-requirements",
    meta: system_45requirementsjk8TcwXSaWMeta || {},
    alias: system_45requirementsjk8TcwXSaWMeta?.alias || [],
    redirect: system_45requirementsjk8TcwXSaWMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/system-requirements.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93a8IqAOqDnhMeta?.name ?? "tag-slug",
    path: _91_46_46_46slug_93a8IqAOqDnhMeta?.path ?? "/tag/:slug(.*)*",
    meta: _91_46_46_46slug_93a8IqAOqDnhMeta || {},
    alias: _91_46_46_46slug_93a8IqAOqDnhMeta?.alias || [],
    redirect: _91_46_46_46slug_93a8IqAOqDnhMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/tag/[...slug].vue").then(m => m.default || m)
  },
  {
    name: terms_45service_45use_45agreementM2fqjXwr4PMeta?.name ?? "terms-service-use-agreement",
    path: terms_45service_45use_45agreementM2fqjXwr4PMeta?.path ?? "/terms-service-use-agreement",
    meta: terms_45service_45use_45agreementM2fqjXwr4PMeta || {},
    alias: terms_45service_45use_45agreementM2fqjXwr4PMeta?.alias || [],
    redirect: terms_45service_45use_45agreementM2fqjXwr4PMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/terms-service-use-agreement.vue").then(m => m.default || m)
  },
  {
    name: top_45featuresr3h4KIgH78Meta?.name ?? "top-features",
    path: top_45featuresr3h4KIgH78Meta?.path ?? "/top-features",
    meta: top_45featuresr3h4KIgH78Meta || {},
    alias: top_45featuresr3h4KIgH78Meta?.alias || [],
    redirect: top_45featuresr3h4KIgH78Meta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/top-features.vue").then(m => m.default || m)
  }
]