<template>
	<component
		:is="props.tag"
		class="row"
		:class="props.class"
	>
		<slot />
	</component>
</template>

<script setup lang="ts">
type Props = {
	class?: string;
	tag?: string;
};

const props = withDefaults(defineProps<Props>(), {
	tag: "div"
});
</script>

<style lang="scss">
.row {
	padding: 40px 0;
	display: flex;
	justify-content: center;

	@include breakpoint(lg) {
		padding: 80px 0;
	}

	&.pt-0 {
		padding-top: 0;
	}

	&.pb-0 {
		padding-bottom: 0 !important;
	}
}
</style>
