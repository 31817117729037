// store.js
import { reactive, toRef } from "vue";

export const store = reactive({
    showSignUpModal: false,
    toggleSignUp() {
        this.showSignUpModal = !this.showSignUpModal;
    },
});

