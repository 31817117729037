
export default defineNuxtRouteMiddleware((to, from) => {

    if (['/project-x/transcript/', '/watchlist/'].includes(from.path)) {
        window.location.href = '/';
    }

    if (to.path === '/top-features/' || to.path === '/broker-integration/') {
        return navigateTo('/platform/');
    }
    if (to.path === '/building-a-watchlist/') {
        return navigateTo('/create-stock-watchlist/');
    }
    if (to.path === '/project-x/transcript/' && from.path !== '/project-x/transcript/') {
        window.location.href = 'https://lp.stockstotrade.com/project-x/transcript/';
    }

    if (to.path === '/watchlist/' && from.path !== '/watchlist/') {
        window.location.href = 'https://lp.stockstotrade.com/watchlist/';
    }

    if (
        from.path === '/contact/' && to.path === '/contact/') {
        window.location.href = '/';
        return;
    }

    if (
        to.path === '/contact/') {
        window.location.href = 'https://support.stockstotrade.com/hc/en-us/requests/new';
    }
    const imageFormats = ['.jpg', '.jpeg', '.png', '.gif', '.svg', '.webp'];
    const scriptFormats = ['.php', '.js', '.css2', '.json', '.css'];

    const hasFormat = (path, formats) => formats.some(format => path.endsWith(format));

    if (!to.path.endsWith('/') && !hasFormat(to.path, [...imageFormats, ...scriptFormats])) {
        return navigateTo(to.path + '/');
    }
})