<script setup lang="ts">
import { h } from "vue";
type Props = {
	text: string;
	tag: "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
	type?: "primary";
};

const props = defineProps<Props>();

const { tag, text, type } = props;
const classes = computed(() => {
	let size = "text-xl";

	switch (tag) {
		case "h1":
			size = "title";
			break;
		case "h2":
			size = type === "primary" ? "title" : "subtitle";
			break;
		case "h3":
			size = "text-3xl";
			break;
		case "h4":
			size = "text-2xl";
			break;
		case "h5":
			size = "subtitle";
			break;
		case "h6":
			size = "text-lg";
			break;
		default:
			size = "text-xl";
			break;
	}
	return `${size} leading-none lg:leading-tight`;
});

const render = () => h(tag, { class: `${classes.value}`, innerHTML: text });
</script>

<template>
	<render />
</template>

<style lang="scss" scoped>
.title {
	font-size: 32px;
	font-style: normal;
	line-height: 125%;
	letter-spacing: 0;
	@include openSauceSans500;
	@include breakpoint(lg) {
		font-size: 56px;
	}
}

h2.subtitle {
	font-size: 40px;
	line-height: 1.2;

	@include breakpoint(lg) {
		font-size: 48px;
		line-height: 48px;
	}
}
</style>
