<template>
	<Layout>
		<Row class="bg-white py-[64px]">
			<Container>
				<div class="flex flex-col items-center mb-[32px]">
					<img
						v-if="error && error.statusCode === 404"
						src="/images/404.svg"
						class="max-w-[260px] mb-[32px]"
						alt="Four Oh Four Image"
					/>
					<img
						v-else
						src="/images/server-error.svg"
						class="max-w-[260px] mb-[32px]"
						alt="Server Error Image"
					/>

					<h1
						class="gradient-title text-center text-[32px] lg:text-[56px] text-slate-900 mb-[32px]"
					>
						Error {{ error && error.statusCode }}
					</h1>
					<p class="text-3xl text-center text-slate-900">
						{{
							env === "dev" && error && error.statusCode === 500
								? error.message
								: message
						}}
					</p>

					<SttButton
						v-if="error && error.statusCode === 500"
						:onClick="handleError"
						class="mt-[36px] px-[16px] py-[16px]"
					>
						Try Again
					</SttButton>

					<SttButton
						v-else
						:onClick="handleError"
						class="mt-[36px] px-[16px] py-[16px]"
					>
						Try Again
					</SttButton>
				</div>
			</Container>
		</Row>
		<Head>
			<Title>Page Not Found - StocksToTrade</Title>
			<Meta
				name="description"
				content="Since 2009, StocksToTrade has helped new and seasoned traders make consistently profitable trades.  Try us today for only $7.00"
			/>
		</Head>
	</Layout>
</template>

<script setup>
import Layout from "~/layouts/default.vue";
// default props available on error.vue
const $router = useRoute();

const { env } = useRuntimeConfig().public;
const props = defineProps({
	error: Object
});

const message = ref("");
const error = useError();

if (error && error.value && error.value.statusCode === 404) {
	message.value = "Oops! Page not found 😔";
	const newUrl = "/404/";
	window.history.pushState("", "", newUrl);
}

if (error && error.value && error.value.statusCode === 500) {
	message.value = "Oops!😔 We are facing some issues.";
}

if (!error.value) {
	error.value.statusCode = 500;
	message.value = "Oops!😔 We are facing some issues.";
}

// clear error and redirect to home page
const handleError = () => {
	clearError();
	window.location.href = "/";
};

watch(
	() => $router.fullPath,
	(newPage, oldPage) => {
		if (newPage !== oldPage) {
			window.location.href = newPage;
			clearError();
		}
	}
);
</script>
