<template>
	<div
		class="gradient-border"
		:class="props.parentClass"
	>
		<div
			class="gradient-border-wrapper"
			:class="props.class"
		>
			<slot />
		</div>
	</div>
</template>

<script setup>
const props = defineProps(["class", "parentClass"]);
</script>
