<template>
	<div class="nav-link-block border-b border-solid">
		<h3
			class="nav-link"
			:class="{
				'login-mobile': activeIndex === 'login-block'
			}"
			@click="toggleActive('login-block')"
		>
			<svg
				class="mr-[8px]"
				stroke="currentColor"
				fill="none"
				stroke-width="0"
				viewBox="0 0 24 24"
				height="24"
				width="24"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					fill-rule="evenodd"
					clip-rule="evenodd"
					d="M16 9C16 11.2091 14.2091 13 12 13C9.79086 13 8 11.2091 8 9C8 6.79086 9.79086 5 12 5C14.2091 5 16 6.79086 16 9ZM14 9C14 10.1046 13.1046 11 12 11C10.8954 11 10 10.1046 10 9C10 7.89543 10.8954 7 12 7C13.1046 7 14 7.89543 14 9Z"
					fill="currentColor"
				></path>
				<path
					fill-rule="evenodd"
					clip-rule="evenodd"
					d="M12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1ZM3 12C3 14.0902 3.71255 16.014 4.90798 17.5417C6.55245 15.3889 9.14627 14 12.0645 14C14.9448 14 17.5092 15.3531 19.1565 17.4583C20.313 15.9443 21 14.0524 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12ZM12 21C9.84977 21 7.87565 20.2459 6.32767 18.9878C7.59352 17.1812 9.69106 16 12.0645 16C14.4084 16 16.4833 17.1521 17.7538 18.9209C16.1939 20.2191 14.1881 21 12 21Z"
					fill="currentColor"
				></path>
			</svg>
			Sign In

			<img
				src="/images/expand.svg"
				alt=""
				class="ml-auto"
			/>
		</h3>
		<div
			v-if="showMemberSubMenu"
			class="login-block nav-submenu-container flex-col items-center"
			:class="{
				active: activeIndex === 'login-block'
			}"
		>
			<NuxtLink
				v-for="link in memberLinks"
				:key="link.title"
				:to="link.url"
				target="_blank"
				class="member-link"
				@click.prevent="handleMemberLinkClick(link.url)"
			>
				{{ link.title }}
			</NuxtLink>
		</div>
	</div>
	<div
		v-for="(menu, index) in props.navigation"
		:key="menu.ID"
		class="nav-link-block"
		:class="{
			active: activeIndex === index
		}"
		@click="toggleActive(index)"
	>
		<NuxtLink
			:to="menu.url"
			v-if="menu.url !== '/#'"
			class="nav-link"
		>
			{{ menu.title }}

			<img
				v-if="menu.children"
				src="/images/expand.svg"
				alt=""
			/>
		</NuxtLink>
		<h3
			v-else
			class="nav-link"
		>
			{{ menu.title }}

			<img
				v-if="menu.children"
				src="/images/expand.svg"
				alt=""
			/>
		</h3>

		<div
			v-if="menu.children"
			class="nav-submenu-container"
			:class="{
				active: activeIndex === index
			}"
		>
			<div class="xl:container mx-auto lg:grid grid-cols-4">
				<div
					v-for="(submenu, i) in menu.children"
					:key="i"
					class="nav-menu-block"
				>
					<Heading
						tag="h4"
						:text="submenu.title"
						class="nav-submenu-title"
					/>

					<NuxtLink
						v-for="item in submenu.children"
						:key="item.title"
						class="nav-submenu-link"
						:to="item.url"
						target="_self"
					>
						{{ item.title }}
					</NuxtLink>
				</div>
			</div>
		</div>
	</div>
	<div class="watch-live-container">
		<watch-live :isVisible="false" />
	</div>
</template>

<script setup>
const props = defineProps({
	navigation: {
		type: Array,
		required: true
	}
});

const showMemberSubMenu = ref(true);

const activeIndex = ref(null);
const memberLinks = [
	{ title: "Login", url: "https://orders.stockstotrade.com/cp/sign_in" },
	{
		title: "University",
		url: "https://university.stockstotrade.com/wp-login.php"
	}
];

const toggleActive = (index) => {
	if (activeIndex.value === index) {
		activeIndex.value = null;
	} else {
		activeIndex.value = index;
	}
};

const handleMemberLinkClick = (url) => {
	nextTick(() => {
		window.open(url, "_blank");
	});
};

onMounted(async () => {
	if (process.client) {
		await setTimeout(() => {
			const adbutler_link = document.querySelectorAll(".watch-live-container a");

			if (adbutler_link) {
				adbutler_link.forEach((item) => item.setAttribute("target", "_blank"));
			}
		}, 1000);
	}
});
</script>

<style lang="scss" scoped>
.mobile-menu {
	background-color: white;
	border-radius: 8px;
	padding: 8px;

	&.close {
		background-color: transparent;
	}
}

.header {
	background-color: $primary-900;

	.container {
		padding: 10px 0;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	padding: 0 16px;
}

.nav-link-container {
	column-gap: 24px;
}

.nav-link-block:hover .nav-link {
	color: #55abf9;
}

.nav-link-block .nav-submenu-container.active {
	display: block;
}

.nav-submenu-container {
	display: none;

	.container {
		display: grid;
		grid-template-columns: repeat(4, 1fr);
		grid-auto-flow: dense;
	}
}
.login-mobile {
	img {
		width: 24px;
		transform: rotate(180deg);
	}
}
.nav-submenu-container.login-block {
	display: none;

	&.active {
		display: flex;

		img {
			width: 24px;
			transform: rotate(180deg);
		}
	}
}

.nav-menu-block {
	display: flex;
	flex-direction: column;
	column-gap: 16px;
}

.nav-submenu-container {
	position: absolute;
	width: 100%;
	left: 0;
	top: 59px;
	background: $blue-700;
	padding: 20px 0;
}

.nav-submenu-title {
	color: white;
	font-size: 16px;
	font-family: "DM sans";
	margin-bottom: 12px;
	text-transform: uppercase;
}

.nav-submenu-link {
	font-size: 16px;
	font-weight: 400;
	padding: 8px 0;
	color: $primary-50;
	font-family: "DM sans";

	&:hover {
		color: $yellow-500;
		background-color: $yellow-50;
	}
}

.mobile-nav-link-container {
	.nav-link-block {
		padding: 12px 0;
		border-bottom: 1px solid $primary-50;

		&.active {
			.uppercase {
				img {
					width: 24px;
					transform: rotate(180deg);
				}
			}
		}
	}

	.nav-link {
		color: $slate-800;
		font-weight: 700;
		font-size: 18px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		img {
			transition: all 200ms ease-in-out;
			width: 24px;
		}
	}

	.mobile-live-btn {
		display: flex;
		gap: 16px;
		padding: 16px 0;
		max-width: 210px;
		border-top: 6px solid #eab308;
	}

	.nav-submenu-container {
		display: none;
		position: relative;
		top: 0;
		padding: 12px 0;
		background-color: transparent;
	}

	.nav-submenu-title {
		color: $slate-800;
		font-weight: 600;
	}

	.nav-submenu-link {
		font-size: 16px;
		font-weight: 400;
		padding: 8px 16px;
		line-height: 1.25;
		color: $slate-800;
		display: inline-block;
		@include openSauceSans500;
		border-left: 4px solid #a9d1fb;
		&.active {
			color: $yellow-700;
			background-color: $yellow-50;
			border-left-color: $yellow-500;
		}
	}

	.nav-menu-block {
		margin-bottom: 20px;

		&:last-child {
			margin-bottom: 0;
		}
	}
}

.nav-link-block {
	.nav-link:first-child {
		color: $blue-900;
	}

	&.active img {
		transform: rotate(180deg);
	}
}
.nav-link-block.active {
	.nav-link:first-child {
		color: #55abf9;
	}
}

.search-block {
	margin: 8px 0;
	.search-container {
		padding: 12px;
		border-radius: 20px;
		border-radius: 20px;
		border: 2px solid #3194fb;
		box-shadow: 0px 0px 16px #0e488788;

		.search-field {
			color: black;
		}
	}
}

.watch-live-container {
	margin-top: 16px;
	justify-content: center;
	align-items: center;
}

.member {
	display: flex;

	svg {
		margin-right: 12px;
	}
}

.login-section {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	column-gap: 16px;
}
.member-link {
	width: 269px;
	color: white !important;
	border-radius: 20px;
	text-align: center;
	font-size: 24px;
	font-weight: 800;
	padding: 8px 0;
	font-family: "DM sans";
	background: linear-gradient(54deg, #2fccfa 8.05%, #309bfb 98% 91.58%);
	margin: 8px 0;
}
</style>
