import axios from "axios";
import dayjs from "dayjs";
import customParseFormat from 'dayjs/plugin/customParseFormat';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { load } from "cheerio";

dayjs.extend(customParseFormat);
dayjs.extend(utc);
dayjs.extend(timezone);

export const fetchLeftOverInformation = async ({ hostname, post_id }) => {
    const path =
        "wp-json/stt/comments-with-ratings/" +
        post_id +
        "?random_to_avoid_caching=" +
        Math.random();

    const commentsResponse = await axios.get(`${hostname}${path}`);

    return commentsResponse.data;

};

function isWithinTimeRanges() {
    // Define the time ranges for Monday to Thursday and Friday
    const timeRanges = {
        mondayToThursday: [
            ['08:00 AM', '08:30 AM'],
            ['10:15 AM', '10:45 AM'],
            ['12:45 PM', '01:15 PM'],
            ['03:15 PM', '03:45 PM'],
            ['05:45 PM', '06:15 PM'],
            ['08:45 PM', '09:15 PM']
        ],
        friday: [
            ['08:00 AM', '08:30 AM'],
            ['10:15 AM', '10:45 AM'],
            ['12:45 PM', '01:15 PM'],
            ['03:15 PM', '03:45 PM'],
            ['07:45 PM', '08:15 PM']
        ]
    };

    // Get the current time in EST
    const now = dayjs().tz('America/New_York');
    const dayOfWeek = now.day(); // Day of the week (0 = Sunday, 1 = Monday, ..., 6 = Saturday)

    // Select the appropriate time ranges based on the current day
    let rangesToCheck = [];
    if (dayOfWeek >= 1 && dayOfWeek <= 4) { // Monday to Thursday
        rangesToCheck = timeRanges.mondayToThursday;

    } else if (dayOfWeek === 5) { // Friday
        rangesToCheck = timeRanges.friday;


    } else {
        return false; // Not within the specified days
    }

    // Check if the current time is within any of the specified ranges
    for (let [startTime, endTime] of rangesToCheck) {

        if (now.isAfter(dayjs.tz(startTime, 'hh:mm A', 'America/New_York')) && now.isBefore(dayjs.tz(endTime, 'hh:mm A', 'America/New_York'))) {
            return true;
        }
    }
    return false;
}


function formatDate(date) {
    return dayjs(date).format("MMMM D. YYYY");
}
function formatCommentDate(inputDate) {
    return dayjs.tz(inputDate, 'YYYY-MM-DD HH:mm:ss', 'America/Los_Angeles').format("MMM. DD, YYYY [at] h:mm a");
}
const dateFormat = (date) => {
    return dayjs(date).format("MMM. D, YYYY");
}


const isResolution = (breakpoint) => {
    if (breakpoint == 'sm' && window.innerWidth < 768) return true;

    return false;
}


const truncateText = (text, maxLength) => {
    if (text.length <= maxLength) {
        return text;
    } else {
        return text.substring(0, maxLength) + "...";
    }
}

export const calculateReadingTime = (content) => {

    if (!content) return 0;
    // Assuming an average reading speed of 200 words per minute
    const wordsPerMinute = 200;

    // Count the number of words in the content
    const wordCount = content.split(/\s+/).length;

    // Calculate the estimated reading time in minutes
    const readingTimeMinutes = wordCount / wordsPerMinute;

    // Round up the reading time to the nearest whole number
    const roundedReadingTime = Math.ceil(readingTimeMinutes);

    return roundedReadingTime;
};


export const shuffleArray = (array) => {
    const newArray = [...array]; // Create a copy of the original array
    for (let i = newArray.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [newArray[i], newArray[j]] = [newArray[j], newArray[i]];
    }
    return newArray;

}

const formatLink = (url) => {
    const decodedUrl = decodeURI(url);
    const lowercaseString = decodedUrl.toLowerCase();
    return "/" + lowercaseString + "/";
}

const filterCategory = (id, categories) => {
    const category = categories.filter((category) => category.id === id);

    return category[0];
}

export const parse_saswpSchema = (content, hostname, current_hostname) => {
    try {
        const schema_json = content.map((item, i) => {
            let obj = item;

            if (item.author && item.author.url) {
                let url = obj.author.url.replace(hostname, current_hostname);
                let sameAs = obj.author.sameAS;

                if (sameAs && sameAs.length > 0) {
                    sameAs[0] = sameAs[0].replace(hostname, current_hostname);
                }

                obj = { ...obj, author: { ...obj.author, url, sameAs } };
            }

            if (item["@id"]) {
                let id = item["@id"];

                id = id.replace(hostname, current_hostname);

                obj = { ...obj, "@id": id };
            }

            if (item.url) {
                let url = obj.url.replace(hostname, current_hostname);

                obj = { ...obj, url };
            }
            if (item.isPartOf && item.isPartOf["@id"]) {
                obj.isPartOf["@id"] = obj.isPartOf["@id"].replace(
                    hostname,
                    current_hostname
                );
            }

            if (Array.isArray(item.image) && item.image && item.image.length > 0) {
                obj.image = obj.image.map((x) => {
                    if (x["@id"]) {
                        let id = x["@id"];
                        id = id.replace(hostname, current_hostname);

                        return { ...x, "@id": id };
                    }

                    return { ...x };
                });
            }
            if (!Array.isArray(item.image) && item.image && item.image["@id"]) {
                obj.image["@id"] = item.image["@id"].replace(hostname, current_hostname);
            }

            if (item.logo && item.logo["@id"]) {
                obj.logo["@id"] = item.logo["@id"].replace(hostname, current_hostname);
            }

            if (item.sameAs && item.sameAs.length > 0) {
                obj.sameAs = obj.sameAs.map((x) => {
                    const parsed = x.replace(hostname, current_hostname);

                    return parsed;
                });
            }

            if (item["@graph"]) {
                obj["@graph"] = obj["@graph"].map((item) => {
                    let updated = item;

                    if (item["@id"]) {
                        updated["@id"] = item["@id"].replace(hostname, current_hostname);
                    }

                    if (item["url"]) {
                        updated["url"] = item["url"].replace(hostname, current_hostname);
                    }

                    return updated;
                });
            }
            if (item["breadcrumb"] && item["breadcrumb"]["@id"]) {
                obj["breadcrumb"]["@id"] = item["breadcrumb"]["@id"].replace(
                    hostname,
                    current_hostname
                );
            }

            if (item.itemListElement) {
                obj.itemListElement = obj.itemListElement.map((element) => {
                    let updated = element;

                    if (element.item && element.item["@id"]) {
                        updated.item["@id"] = element.item["@id"].replace(
                            hostname,
                            current_hostname
                        );
                    }
                    if (element.item && !element.item["@id"]) {
                        updated.item = element.item.replace(hostname, current_hostname);
                    }

                    return updated;
                });
            }
            if (item.potentialAction && item.potentialAction.length > 0) {
                obj.potentialAction = obj.potentialAction.map((element) => {
                    let updated = element;

                    if (element.target && element.target.urlTemplate) {
                        updated.target.urlTemplate = element.target.urlTemplate.replace(
                            hostname,
                            current_hostname
                        );
                    }

                    return updated;
                });
            }

            if (item.mainEntityOfPage) {

                let mainEntityOfPage = obj.mainEntityOfPage['@id'] ? obj.mainEntityOfPage['@id'].replace(
                    hostname,
                    current_hostname
                ) : obj.mainEntityOfPage.replace(
                    hostname,
                    current_hostname
                );

                obj = { ...obj, mainEntityOfPage };
            }

            if (item.publisher) {
                if (item.publisher.url) {
                    let url = obj.publisher.url.replace(
                        hostname.slice(0, -1),
                        current_hostname.slice(0, -1)
                    );
                    let publisher = { ...item.publisher, url };
                    obj = { ...obj, publisher };
                }
                if (item.publisher["@id"]) {
                    obj.publisher["@id"] = obj.publisher["@id"].replace(
                        hostname.slice(0, -1),
                        current_hostname.slice(0, -1)
                    );
                }
            }

            return { ...obj };
        });

        return schema_json;
    } catch (e) {
        console.log("ERROR WHILE PARSING", e);
        throw createError({
            statusCode: 500,
            fatal: true
        });
    }
};



const schemaContentParser = (content, hostname) => {
    const imageList = [];
    const $ = load(content, {
        decodeEntities: false // Prevent HTML entity decoding
    });

    $("img").each((i, el) => {
        imageList.push({
            "@type": "ImageObject",
            url: el.attribs.src.includes("http://contentstt.wpenginepowered.com/") ? el.attribs.src.replace("http://contentstt.wpenginepowered.com/", hostname) : el.attribs.src.includes("https://contentstt.wpenginepowered.com/") ? el.attribs.src.replace("https://contentstt.wpenginepowered.com/", hostname) : el.attribs.src,
            width: el.attribs.width,
            height: el.attribs.height
        });
    });

    $('[data-beyondwords-player="true"], script, style, img').remove();
    // Extract text content
    const textContent = $("body").text();

    return { textContent, imageList };
};

export const schemaMaker = (post, current_hostname, $categories, hostname, faq_code) => {

    console.log('1 schemaMaker')
    let payload = [];

    const category = post.categories && post.categories.length > 0 ? $categories.find((item) => item.id === post.categories[0]) : null;

    if (!category) return [];

    console.log('2 schemaMaker', current_hostname + post.slug, category)

    const breadcrumb_list = {
        "@type": "BreadcrumbList",
        "@id": current_hostname + post.slug + "#breadcrumb",
        itemListElement: [
            {
                "@type": "ListItem",
                position: 1,
                item: {
                    "@type": "Thing",
                    "@id": current_hostname,
                    name: "StocksToTrade"
                }
            },
            {
                "@type": "ListItem",
                position: 2,
                item: {
                    "@type": "Thing",
                    "@id": post.link.includes('/best-screeners/') ? (current_hostname + 'best-screeners/' + post.slug + '/') : current_hostname + "category/" + category.slug + "/",
                    name: post.link.includes('/best-screeners/') ? 'Best Screeners' : category.name
                }
            },
            {
                "@type": "ListItem",
                position: 3,
                item: {
                    "@type": "Thing",
                    "@id": current_hostname + post.slug + "/",
                    name: post.title.rendered
                }
            }
        ]
    };

    console.log('3 schemaMaker')


    payload.push(breadcrumb_list);
    if (!post.link.includes('/best-screeners/')) {
        let article = {
            "@type": "BlogPosting",
            "@id": current_hostname + post.slug + "/#BlogPosting",
            url: current_hostname + post.slug + "/",
            inLanguage: "en-US",
            mainEntityOfPage: current_hostname + post.slug + "/",
            headline: post.title.rendered,
            description: post.yoast_head_json.description
        };

        const parsedStuff = schemaContentParser(post.content.rendered, hostname);

        article.articleBody = parsedStuff.textContent;

        let featuredImage = {
            "@type": "ImageObject",
            "@id": current_hostname + post.slug + "/#primaryimage",
            url:
                post._embedded["wp:featuredmedia"] && post._embedded["wp:featuredmedia"][0]
                    ? (post._embedded["wp:featuredmedia"][0].source_url && post._embedded["wp:featuredmedia"][0].source_url.includes('https://contentstt.wpenginepowered.com/') ? post._embedded["wp:featuredmedia"][0].source_url.replace('https://contentstt.wpenginepowered.com/', hostname) :
                        post._embedded["wp:featuredmedia"][0].source_url && post._embedded["wp:featuredmedia"][0].source_url.includes('http://contentstt.wpenginepowered.com/') ? post._embedded["wp:featuredmedia"][0].source_url.replace('http://contentstt.wpenginepowered.com/', hostname) :
                            post._embedded["wp:featuredmedia"][0].source_url)
                    :
                    "",
            width:
                post._embedded["wp:featuredmedia"] &&
                    post._embedded["wp:featuredmedia"][0] &&
                    post._embedded["wp:featuredmedia"][0].media_details &&
                    post._embedded["wp:featuredmedia"][0].media_details.width
                    ? post._embedded["wp:featuredmedia"][0].media_details.width
                    : "",
            height:
                post._embedded["wp:featuredmedia"] &&
                    post._embedded["wp:featuredmedia"][0] &&
                    post._embedded["wp:featuredmedia"][0].media_details &&
                    post._embedded["wp:featuredmedia"][0].media_details.height
                    ? post._embedded["wp:featuredmedia"][0].media_details.height
                    : ""
        };


        // if (post.tags && post.tags.length > 0)
        // 	article.keywords = $tags.find((item) => item.id === post.tags[0]).name + ",";

        const schema_parse =
            post.yoast_head_json.WordPressAuthor_YoastHeadJson_Schema_;

        const datesFromSchema = schema_parse["@graph"].find(
            (item) => item["@type"][0] === "Article" || item["@type"] === "Article"
        ) || { datePublished: "", dateModified: "" };


        const timBohen = {
            "@type": "Person",
            name: "Tim Bohen",
            url: current_hostname + "authors/tim-bohen/",
            image: {
                "@type": "ImageObject",
                url: "https://secure.gravatar.com/avatar/ffab25dfa1b032865bcfe0a47dde563a?s=96&d=mm&r=g",
                height: 96,
                width: 96
            }
        };

        const stockstotrade = {
            "@type": "Person",
            name: "stockstotrade",
            url: current_hostname + "authors/stockstotrade/",
            image: {
                "@type": "ImageObject",
                url: "https://secure.gravatar.com/avatar/1f12a26284bdf64b81d4c9b929da71bd?s=96&d=mm&r=g",
                height: 96,
                width: 96
            }
        };

        const zachB = {
            "@type": "Person",
            name: "Zach B",
            url: current_hostname + "authors/zach/",
            sameAs: current_hostname,
            sameAs: "zach",
            image: {
                "@type": "ImageObject",
                url: "https://secure.gravatar.com/avatar/1f12a26284bdf64b81d4c9b929da71bd?s=96&d=mm&r=g",
                height: 96,
                width: 96
            }
        };

        const mattMonaco = {
            "@type": "Person",
            name: "Matthew Monaco",
            url: current_hostname + "authors/matt_monaco/",
            image: {
                "@type": "ImageObject",
                url: "https://secure.gravatar.com/avatar/df7445b557ce92f026428d2d68081786?s=96&d=mm&r=g",
                height: 96,
                width: 96
            }
        };

        const kyleW = {
            "@type": "Person",
            name: "Kyle Williams",
            url: current_hostname + "authors/kyle-williams/",
            sameAs: "zach",
            image: {
                "@type": "ImageObject",
                url: "https://secure.gravatar.com/avatar/808b450f155fb2e65791a68d1f673bf5?s=96&d=mm&r=g",
                height: 96,
                width: 96
            }
        };

        const staffBio = {
            "@type": "Person",
            name: "Staff",
            url: current_hostname + "authors/staff/",
            image: {
                "@type": "ImageObject",
                url: "https://secure.gravatar.com/avatar/66fcee21aa5674d0bb977b532006c482?s=96&d=mm&r=g",
                height: 96,
                width: 96
            }
        };

        const publisher = {
            "@type": "Organization",
            name: "StocksToTrade",
            url: current_hostname,
            image: {
                "@type": "ImageObject",
                url: hostname + "wp-content/uploads/2016/06/sttlogo.png",
                height: 175,
                width: 25
            }
        };

        let author = (function () {
            if (post.author === 6) return timBohen;
            if (post.author === 2) return stockstotrade;
            if (post.author === 93) return zachB;
            if (post.author === 103) return mattMonaco;
            if (post.author === 105) return kyleW;
            if (post.author === 101) return staffBio;
        })();

        let editor = (function () {
            if (post.author === 6) return timBohen;
            if (post.author === 2) return stockstotrade;
            if (post.author === 93) return zachB;
            if (post.author === 103) return mattMonaco;
            if (post.author === 105) return kyleW;
            if (post.author === 101) return staffBio;
        })();
        article.datePublished = datesFromSchema.datePublished;
        article.dateModified = datesFromSchema.dateModified;
        article.author = author;

        article.editor = editor;

        article.publisher = publisher;

        if (
            post._embedded["wp:featuredmedia"] &&
            post._embedded["wp:featuredmedia"][0]
        ) {
            article.image = [featuredImage, ...parsedStuff.imageList];
        } else {
            article.image = [...parsedStuff.imageList];
        }

        const thumbnailUrl =
            post._embedded["wp:featuredmedia"] &&
                post._embedded["wp:featuredmedia"][0] &&
                post._embedded["wp:featuredmedia"][0].source_url
                ? post._embedded["wp:featuredmedia"][0].source_url.split(".jpg")[0] +
                "-150x150.jpg"
                : "";

        payload.push(article);


        if (post.categories && [77].includes(post.categories[0])) {
            let videoObject = {
                "@type": "VideoObject",
                "@id": current_hostname + "blog/" + post.slug + "/#videoobject",
                url: current_hostname + "blog/" + post.slug + "/",
                inLanguage: "en-US",
                mainEntityOfPage: current_hostname + "blog/" + post.slug + "/",
                headline: post.title.rendered,
                datePublished: datesFromSchema.datePublished,
                dateModified: datesFromSchema.dateModified,
                description: post.yoast_head_json.description,
                name: post.title.rendered,
                uploadDate: datesFromSchema.datePublished,
                thumbnailUrl,
                author: author,
                publisher: publisher,
                image: [featuredImage, ...parsedStuff.imageList]
            };

            payload.push(videoObject);
        }

        if (category && category.slug === "podcast") {
            let podcastObj = {
                "@type": "PodcastEpisode",
                "@id": current_hostname + "blog/" + post.slug + "/#PodcastEpisode",
                url: current_hostname + "blog/" + post.slug + "/",
                name: post.title.rendered,
                datePublished: datesFromSchema.datePublished,
                dateModified: datesFromSchema.dateModified,
                image: [featuredImage]
            };

            payload.push(podcastObj);
        }

        if (faq_code) {
            payload.push({
                "@context": "https://schema.org",
                "@type": "FAQPage",
                mainEntity: faq_code.FAQPage.mainEntity.map((question) => ({
                    "@type": "Question",
                    name: question.name,
                    acceptedAnswer: {
                        "@type": "Answer",
                        text: question.acceptedAnswer.text
                    }
                }))
            });
        }

    }

    console.log('4 schemaMaker')

    return payload;
};

function stringEndsWithOneOfWords(str, words) {

    return words.some(word => str.endsWith(word));
}


function formatNumber(number) {
    if (number >= 1000000000) {
        return (number / 1000000000).toFixed(2) + 'B'; // Billion
    } else if (number >= 1000000) {
        return (number / 1000000).toFixed(2) + 'M'; // Million
    } else {
        return number.toString(); // Less than a million
    }
}

export { formatNumber, formatLink, stringEndsWithOneOfWords, filterCategory, formatCommentDate, dateFormat, isResolution, truncateText, isWithinTimeRanges }