<template>
	<span
		v-if="to"
		class="blockrelative"
		:class="`${fullWidth ? 'w-full' : ''} ${centered ? 'text-center' : ''}`"
	>
		<NuxtLink
			ref="button"
			:href="to"
			:target="blank ? '_blank' : ''"
			:class="`cta btn ${
				type === 'secondary'
					? 'secondary'
					: type === 'gradient-dark'
						? 'gradient-dark'
						: type === 'gradient-light'
							? 'gradient-light'
							: type === 'basic-white'
								? 'basic-white'
								: ''
			} ${props.buttonClass} ${props.height ? ` h-[${props.height}px]` : ''} ${
				props.fullWidth ? ' w-full justify-center' : ''
			}`"
		>
			<slot />
			<svg
				width="24"
				height="24"
				viewBox="0 0 24 24"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M14 4.92993L12.5 6.42993L17.0703 11.0002H3V13.0002H17.0703L12.5 17.5706L14 19.0706L21.0703 12.0002L14 4.92993Z"
					:fill="`${
						type === 'secondary'
							? 'white'
							: type === 'basic-white'
								? 'black'
								: type == 'primary' || type == ''
									? 'black'
									: 'white'
					}`"
				/>
			</svg>
		</NuxtLink>
	</span>

	<button
		v-else-if="type === 'button'"
		:class="`cta btn ${type === 'secondary' ? 'secondary' : ''} ${
			props.fullWidth ? 'w-full justify-center' : ''
		}`"
	>
		<slot />
	</button>

	<button
		v-else
		type="submit"
		:class="`cta btn ${type === 'secondary' ? 'secondary' : ''} ${
			props.fullWidth ? 'w-full justify-center' : ''
		}`"
	>
		<slot />
		<svg
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M14 4.92993L12.5 6.42993L17.0703 11.0002H3V13.0002H17.0703L12.5 17.5706L14 19.0706L21.0703 12.0002L14 4.92993Z"
				:fill="`${props.type === 'secondary' ? 'white' : '#0d0d12'} `"
			/>
		</svg>
	</button>
</template>

<script setup>
const props = defineProps({
	height: { type: Number },
	centered: {
		type: Boolean,
		default: true
	},
	fullWidth: {
		type: Boolean,
		default: false
	},
	type: {
		type: String,
		required: false,
		default: ""
	},
	to: {
		type: String,
		required: false,
		default: ""
	},
	blank: {
		type: Boolean,
		default: false
	},
	buttonClass: {
		type: String,
		default: ""
	}
});

const button = ref();

onMounted(() => {});
</script>

<style lang="scss" scoped>
.btn.cta {
	display: flex;
	padding: 12px 16px;
	align-items: center;
	gap: 4px;
	border-radius: 12px;
	background: $yellow-300;
	color: $slate-900;
	text-align: center;
	font-size: 16px;
	font-style: normal;
	font-weight: 600;
	line-height: 1.25;
	white-space: nowrap;

	&.secondary {
		background: $blue-300;
		color: white;
		&:hover {
			background: $blue-500;
		}
	}

	&.gradient-dark {
		background: linear-gradient(110.32deg, #091d56 8.05%, #331290 77.58%);
		color: white;
		&:hover {
			background: $blue-500;
		}
	}

	&.gradient-light {
		background: linear-gradient(90deg, #50b5ff 0%, #be55ff 99.95%);
		color: white;
		&:hover {
			background: $blue-500;
		}
	}

	&.basic-white {
		background: white;
		color: black;
		border: 1px solid black;
		&:hover {
			// background: $slate-100;
			background: white;
		}
	}

	@include breakpoint(md) {
		padding: 20px 24px;

		font-size: 18px;
	}

	&:hover {
		background: $yellow-400;
	}
}

a.btn.cta {
	display: inline-flex;
}
</style>
