<template>
	<div
		:id="`reg-optin ${id}`"
		class="infusion-form form-watchlist-cta form optin_form"
		:class="alt ? 'alt' : ''"
	>
		<CtaButton
			:type="ctaAppearence || 'secondary'"
			@click="redirectToPage"
		>
			{{ buttonText || "Sign me up for FREE Trade Alerts" }}
		</CtaButton>
	</div>
</template>

<script setup>
const { id, buttonText, ctaAppearence, alt, altHanlder } = defineProps({
	id: {
		type: String,
		required: true
	},
	buttonText: { type: String },
	ctaAppearence: { type: String },
	alt: {
		type: Boolean
	},
	altHanlder: {
		type: Function
	}
});

const redirectToPage = () => {
	if (alt && altHanlder) {
		setTimeout(() => {
			altHanlder();
		}, 500);
	}
	// window.open("https://lp.stockstotrade.com/algo-trader/", "_blank");
	window.location.href = "https://lp.stockstotrade.com/algo-trader/";
};
</script>
<style lang="scss">
.form-watchlist-cta {
	@include breakpoint(md) {
		&:not(.alt) {
			flex-direction: row;
		}
		gap: 10px;

		&.alt {
			.btn {
				width: 100%;
			}
		}
	}

	button {
		width: 100%;
		justify-content: center;
		@include breakpoint(md) {
			width: auto;
		}
	}
}
</style>
