<template>
	<nav
		ref="mobileHeader"
		class="mobile-nav-link-container"
	>
		<div class="flex justify-between mobile-nav-header">
			<NuxtLink
				id="mobile-logo"
				to="/"
				class="flex align-center"
			>
				<LogoBlack class="w-[166px]" />
			</NuxtLink>

			<button
				class="mobile-menu-btn close xl:hidden"
				@click="toggle"
			>
				<svg
					width="21"
					height="22"
					viewBox="0 0 21 22"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M1.4043 20.2886L19.7891 1.9038"
						stroke="black"
						stroke-width="2"
						stroke-linecap="round"
					/>
					<path
						d="M1.21094 1.71143L19.5957 20.0962"
						stroke="black"
						stroke-width="2"
						stroke-linecap="round"
					/>
				</svg>
			</button>
		</div>
		<mobile-links-block :navigation="navigation" />
	</nav>
</template>

<script setup>
const { navigation, toggle } = defineProps({
	navigation: {
		type: Object,
		required: true
	},
	toggle: {
		type: Function,
		required: true
	}
});
const mobileHeader = ref();
const { current_hostname, hostname } = useRuntimeConfig().public;

watch(mobileHeader, (newNav) => {
	if (process.client && newNav) {
		const links = newNav.querySelectorAll(
			` #mobile-logo, .member-link, .nav-submenu-link, .nav-link-block a.nav-link[href] `
		);

		[...links].map((link) => {
			link.addEventListener(
				"click",
				(e) => {
					if (
						![
							new URL(current_hostname).origin,
							new URL(hostname).origin,
							window.location.origin
						].includes(new URL(link.href).origin)
					) {
						window.open(link.href, "_blank");
					}

					console.log("CLICKING 1");
					setTimeout(() => {
						toggle();
						console.log("CLICKING 2");
					}, 50);

					e.preventDefault();
				},
				{ pasive: true }
			);

			return null;
		});
	}
});
</script>

<style lang="scss" scoped>
#searchform input {
	background-color: white;
	padding: 8px 12px;
	border-top-left-radius: 8px;
	border-bottom-left-radius: 8px;
	border: 1px solid $primary-300;
	width: 100%;
	&:focus {
		outline-width: 0;
		outline-offset: 0;
		border: 1px solid $primary-700;
	}
}
#searchform button[type="submit"] {
	background-color: $primary-700;
	border: 1px solid $primary-700;

	font-weight: 600;
	color: white;
	min-width: 44px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
	border-top-right-radius: 8px;
	border-bottom-right-radius: 8px;
}
.search-bar {
	background-color: white;
	padding: 32px 0;
	margin-left: -16px;
	margin-right: -16px;
	height: 0;
	transition: all 900ms ease-in-out;
	overflow: hidden;
	display: none;
	width: 100%;
}
.search-bar.active {
	height: 104px;
	display: block;
}

.search-bar .container.py-0 {
	padding-top: 0;
	padding-bottom: 0;
}

.mobile-nav-header {
	padding: 20px 0;
	margin-bottom: 16px;
	position: sticky;
	top: 0;
	z-index: 1;
	background-color: white;
}

.mobile-menu {
	background-color: white;
	border-radius: 8px;
	padding: 8px;

	&.close {
		background-color: transparent;
	}
}
.header {
	background-color: $primary-900;

	.container {
		padding: 10px 0;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	padding: 0 16px;
}

.nav-link-container {
	column-gap: 24px;
}

@keyframes pulse-red {
	0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(255, 82, 82, 0.7);
	}

	70% {
		transform: scale(1);
		box-shadow: 0 0 0 10px transparent;
	}

	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 transparent;
	}
}

.blob.red {
	background: #ff5252;
	box-shadow: 0 0 0 0 #ff5252;
	animation: pulse-red 2s infinite;
	position: relative;
	left: -16px;
	@include breakpoint(xl) {
		left: 0;
	}
}

.blob {
	background: #000;
	border-radius: 50%;
	box-shadow: 0 0 0 0 #000;
	margin: 14px 8px 15px 12px;
	height: 10px;
	width: 10px;
	transform: scale(1);
	animation: pulse-black 2s infinite;
}

.nav-link-block:hover .nav-link {
	color: $yellow-500;
}

.nav-link-block .nav-submenu-container.active {
	display: block;
}

.nav-submenu-container {
	display: none;
	.container {
		display: grid;
		grid-template-columns: repeat(4, 1fr);
		grid-auto-flow: dense;
	}
}

.nav-menu-block {
	display: flex;
	flex-direction: column;
	column-gap: 16px;
}

.nav-submenu-container {
	position: absolute;
	width: 100%;
	left: 0;
	top: 59px;
	background: $primary-500;
	padding: 20px 0;
}

.nav-submenu-title {
	color: white;
	font-size: 18px;
	font-family: Rubik;
	margin-bottom: 12px;
}

.nav-submenu-link {
	font-size: 16px;
	font-weight: 400;
	padding: 8px 0;
	color: $primary-50;
	font-family: Rubik;

	&:hover {
		text-decoration: underline;
	}
}

.mobile-nav-link-container {
	position: fixed;
	width: 100%;
	max-height: 100vh;
	left: 0;
	top: 0;
	z-index: 1001;
	padding: 0 16px;
	border-radius: 0px 0px 8px 8px;
	overflow-y: auto;
	background: $white;
	/* Drop Shadow */
	box-shadow: 1px 14px 20px 8px rgba(72, 73, 81, 0.1);
	padding-bottom: 16px;
	height: 100%;

	.mobile-live-btn {
		display: flex;
		gap: 32px 16px;
		justify-content: center;
		padding: 52px 0;
		border-top: 6px solid #eab308;
	}
}
</style>
